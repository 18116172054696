#App {
    text-align: center;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    background-color: black;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    color: white;
    font-family: Helvetica, sans-serif;
}

#landing {
    /* width: 100%;
  height: 100%; */
}
#landingLinks {
    /* width: 100%;
  height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    text-decoration: none;
    color: white;
    margin: 8px;
}
